/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient, HttpRequest, HttpResponse, 
  HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { filter } from 'rxjs/operators/filter';

import { TourGuides } from '../models/tour-guides';

@Injectable()
export class ManagerTourGuidesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerListTourGuidesResponse(params: ManagerTourGuidesService.ManagerListTourGuidesParams): Observable<HttpResponse<TourGuides[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null) __params = __params.set("page", params.page.toString());
    if (params.limit != null) __params = __params.set("limit", params.limit.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/tour-guides`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TourGuides[] = null;
        _body = _resp.body as TourGuides[]
        return _resp.clone({body: _body}) as HttpResponse<TourGuides[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListTourGuides(params: ManagerTourGuidesService.ManagerListTourGuidesParams): Observable<TourGuides[]> {
    return this.managerListTourGuidesResponse(params).pipe(
      map(_r => _r.body)
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */

  managerCreateTourGuidesResponse(body?: TourGuides): Observable<HttpResponse<TourGuides>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/tour-guides`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TourGuides = null;
        _body = _resp.body as TourGuides
        return _resp.clone({body: _body}) as HttpResponse<TourGuides>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerCreateTourGuides(body?: TourGuides): Observable<TourGuides> {
    return this.managerCreateTourGuidesResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerUpdateTourGuidesResponse(body?: TourGuides): Observable<HttpResponse<TourGuides>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "PATCH",
      this.rootUrl + `/manager/tour-guides`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TourGuides = null;
        _body = _resp.body as TourGuides
        return _resp.clone({body: _body}) as HttpResponse<TourGuides>;
      })
    );
  }

  /**
   * @param body - update
   */
  managerUpdateTourGuides(body?: TourGuides): Observable<TourGuides> {
    return this.managerUpdateTourGuidesResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerDeleteTourGuidesResponse(tourGuideId: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/tour-guides/${tourGuideId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerDeleteTourGuides(tourGuideId: number): Observable<void> {
    return this.managerDeleteTourGuidesResponse(tourGuideId).pipe(
      map(_r => _r.body)
    );
  }


  managerDeleteAccumulatedTicketsResponse(id: number): Observable<HttpResponse<void>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    
    let req = new HttpRequest<any>(
      "DELETE",
      this.rootUrl + `/manager/accumulated-tickets/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: void = null;
        
        return _resp.clone({body: _body}) as HttpResponse<void>;
      })
    );
  }

  /**
   * @param userId - undefined
   */
  managerDeleteAccumulatedTickets(id: number): Observable<void> {
    return this.managerDeleteAccumulatedTicketsResponse(id).pipe(
      map(_r => _r.body)
    );
  }
  managerListTourGuidesBySearchResponse(body?: any): Observable<HttpResponse<TourGuides[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/tour-guides/by-search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TourGuides = null;
        _body = _resp.body as TourGuides
        return _resp.clone({body: _body}) as HttpResponse<TourGuides[]>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerListTourGuidesBySearch(body?: any): Observable<TourGuides[]> {
    return this.managerListTourGuidesBySearchResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerCreateCustomerTourGuideResponse(body?: any): Observable<HttpResponse<any[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/customer-tour-guide`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any = null;
        _body = _resp.body as any
        return _resp.clone({body: _body}) as HttpResponse<any[]>;
      })
    );
  }

  /**
   * @param body - Created User object
   */
  managerCreateCustomerTourGuide(body?: any): Observable<any[]> {
    return this.managerCreateCustomerTourGuideResponse(body).pipe(
      map(_r => _r.body)
    );
  }


  managerExportListTourGuidesResponse(body: any): Observable<HttpResponse<string[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/tour-guides/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: string[] = null;
        _body = _resp.body as string[]
        return _resp.clone({body: _body}) as HttpResponse<string[]>;
      })
    );
  }

  /**
   * @param transactionType - undefined
   * @param rfid - undefined
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   */
  managerExportListTourGuides(body: any): Observable<string[]> {
    return this.managerExportListTourGuidesResponse(body).pipe(
      map(_r => _r.body)
    );
  }

  managerExportListCustomerTourGuideResponse(body: any): Observable<HttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      "POST",
      this.rootUrl + `/manager/customer-tour-guide/export`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

      return this.http.request<any>(req).pipe(
        filter(_r => _r instanceof HttpResponse),
        map(_r => {
          let _resp = _r as HttpResponse<any>;
          let _body: string = null;
          _body = _resp.body as string;
          return _resp.clone({ body: _body }) as HttpResponse<string>;
        })
      );
  }
 
  managerGetTourGuidesResponse(params: any): Observable<HttpResponse<TourGuides[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.key_input != null) __params = __params.set("key_input", params.key_input.toString());
    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/tour-guides/by-fullname`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: TourGuides[] = null;
        _body = _resp.body as TourGuides[]
        return _resp.clone({body: _body}) as HttpResponse<TourGuides[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerGetTourGuides(params: any): Observable<TourGuides[]> {
    return this.managerGetTourGuidesResponse(params).pipe(
      map(_r => _r.body)
    );
  }

  managerListAccumulatedTicketsResponse(param?: any): Observable<HttpResponse<any[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
  
    if (param.from_date != null) __params = __params.set("from_date", param.from_date.toString());
    if(param.to_date != null) __params = __params.set("to_date", param.to_date.toString());
    if(param.month != null) __params = __params.set("month", param.month.toString());
    if (param.keywords != null) __params = __params.set("keywords", param.keywords.toString());
    if(param.search != null) __params = __params.set("search", param.search.toString());
    if (param.page != null) __params = __params.set("page", param.page.toString());
    if (param.limit != null) __params = __params.set("limit", param.limit.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/accumulated-tickets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any[] = null;
        _body = _resp.body as any[]
        return _resp.clone({body: _body}) as HttpResponse<any[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListAccumulatedTickets(param: any): Observable<any[]> {
    return this.managerListAccumulatedTicketsResponse(param).pipe(
      map(_r => _r.body)
    );
  }

  managerListCustomerTourGuideResponse(param?: any): Observable<HttpResponse<any[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
  
    if (param.from_date != null) __params = __params.set("from_date", param.from_date.toString());
    if(param.to_date != null) __params = __params.set("to_date", param.to_date.toString());
    if(param.month != null) __params = __params.set("month", param.month.toString());
    if (param.keywords != null) __params = __params.set("keywords", param.keywords.toString());
    if(param.search != null) __params = __params.set("search", param.search.toString());
    if (param.page != null) __params = __params.set("page", param.page.toString());
    if (param.limit != null) __params = __params.set("limit", param.limit.toString());

    let req = new HttpRequest<any>(
      "GET",
      this.rootUrl + `/manager/customer-tour-guide`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      filter(_r => _r instanceof HttpResponse),
      map(_r => {
        let _resp = _r as HttpResponse<any>;
        let _body: any[] = null;
        _body = _resp.body as any[]
        return _resp.clone({body: _body}) as HttpResponse<any[]>;
      })
    );
  }
  /**
   * @param page - The number of items to skip before starting to collect the result set.
   * @param limit - The numbers of items to return.
   * @param disable - The numbers of items to return.
   */
  managerListCustomerTourGuide(param: any): Observable<any[]> {
    return this.managerListCustomerTourGuideResponse(param).pipe(
      map(_r => _r.body)
    );
  }
}

export module ManagerTourGuidesService {
  export interface ManagerListTourGuidesParams {
    page?: number;
    limit?: number;
  }
}
