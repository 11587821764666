/* tslint:disable */
/**
 */
export class TourGuides {
    id?: number;
    fullname?: string;
    phone?: string;
    total_accumulated_tickets?: number;
    email?: string;
    created_at?: string;
    updated_at?: string;
    page?: number;
    limit?: number;
}
